import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import { login, logout, getUserInfo } from '@/api/users'
import { getToken, setToken, removeToken } from '@/utils/cookies'
import router, { resetRouter } from '@/router'
import { PermissionModule } from './permission'
import { TagsViewModule } from './tags-view'
import store from '@/store'
import { getUrlKey } from '@/utils/index'
// const Base64 = require('js-base64').Base64
import { Base64 } from 'js-base64';
export interface IUserState {
  token: string;
  name: string;
  email: string;
  systemCode:any;
  actionUrl:object[]
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public token = getToken() || ''
  public name = ''
  public email = ''
  public systemCode = '';
  public actionUrl:object[] = []

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name
  }

  @Mutation
  private SET_EMAIL(email: string) {
    this.email = email
  }

  @Mutation
  private SET_SYSTEM_CODE(systemCode: any) {
    this.systemCode = systemCode
  }

  @Mutation
  private SET_ACTION_URL(actionUrl: object[]) {
    this.actionUrl = actionUrl
  }

  @Action
  public async Login(userInfo: { username: string, password: string}) {
    let { username, password } = userInfo
    username = username.trim()
    const { data } = await login({ username, password })
    setToken(data.accessToken)
    this.SET_TOKEN(data.accessToken)
  }

  @Action
  public ResetToken() {
    removeToken()
    this.SET_TOKEN('')
  }

  // 获取URL信息
  @Action({ rawError: true })
  public async LoginByUsername() {
    const urlInfo = JSON.parse(Base64.decode(getUrlKey('code') || ''))
    this.SET_TOKEN(urlInfo.token)
    this.SET_SYSTEM_CODE(urlInfo.systemCode)
    this.SET_NAME(urlInfo.username)
    setToken(urlInfo.token)
    sessionStorage.setItem('urlInfo', JSON.stringify(urlInfo))
    return new Promise((resolve, reject) => {
      if (urlInfo) {
        resolve(urlInfo)
      } else {
        reject(new Error('No request to URL parameters'))
      }
    })
  }

  @Action({ rawError: true })
  public async GetUserInfo() {
    let urlInfo
    if (sessionStorage.getItem('urlInfo')) {
      urlInfo = JSON.parse(sessionStorage.getItem('urlInfo') || '{}')
    } else if(getUrlKey('code')) {
      urlInfo = JSON.parse(Base64.decode(getUrlKey('code') || ''))
    }

    try {
      if (this.token === '') {
        throw Error('GetUserInfo: token is undefined!')
      }
      const { data } = await getUserInfo()
      if (!data) {
        throw Error('Verification failed, please Login again.')
      }
      // const { roles, name, avatar, introduction, email } = data.user
      const { name, email, actionUrl } = data
      const { systemCode } = urlInfo
      // roles must be a non-empty array
      // if (!roles || roles.length <= 0) {
      //   throw Error('GetUserInfo: roles must be a non-null array!')
      // }
      this.SET_NAME(name)
      this.SET_SYSTEM_CODE(systemCode)
      this.SET_ACTION_URL(actionUrl)
      this.SET_EMAIL(email)
    } catch (error) {
      console.log(error)
    }
  }

  @Action
  public async ChangeRoles(role: string) {
    // Dynamically modify permissions
    const token = role + '-token'
    this.SET_TOKEN(token)
    setToken(token)
    await this.GetUserInfo()
    resetRouter()
    // Generate dynamic accessible routes based on roles
    PermissionModule.GenerateRoutes({})
    // Add generated routes
    router.addRoutes(PermissionModule.dynamicRoutes)
    // Reset visited views and cached views
    TagsViewModule.delAllViews()
  }

  @Action
  public async LogOut() {
    if (this.token === '') {
      throw Error('LogOut: token is undefined!')
    }
    await logout()
    removeToken()
    resetRouter()

    // Reset visited views and cached views
    TagsViewModule.delAllViews()
    this.SET_TOKEN('')
  }
}

export const UserModule = getModule(User)
