import request from '@/utils/request'

// 获取国家
export const getCountry = (params: any) =>
  request({
    url: '/front/common/get-country-list',
    method: 'get',
    params
  })
// 前台获取时效统计记录
export const getPresciptionLists = (params: any) =>
  request({
    url: '/front/orders/get-timeliness',
    method: 'post',
    data: params
  })
// 前台获取跟踪列表
export const getTrackLists = (params: any) =>
  request({
    url: '/front/orders/index',
    method: 'post',
    data: params
  })
// 前台获取运输方式
export const getShippingLists = (params: any) =>
  request({
    url: '/front/common/get-ship-type',
    method: 'post',
    data: params
  })

// 前台导出妥投订单明细
export const exportdlv = (params: any) =>
  request({
    url: '/front/orders/export-dlv-orders',
    method: 'post',
    data: params
  })
// 前台导出异常订单明细
export const exportorders = (params: any) =>
  request({
    url: '/front/orders/export-abnormal-orders',
    method: 'post',
    data: params
  })
// Front - 前台导出轨迹时效报表
export const exporttimeline = (params: any) =>
  request({
    url: '/front/orders/export-track-timeliness',
    method: 'post',
    data: params
  })

// Front - 前台导出轨迹时效报表
export const getAccountinfo = () =>
  request({
    url: '/front/common/get-account-info',
    method: 'post'
  })

