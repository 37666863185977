import request from '@/utils/login-request.ts'

export const getUsers = (params: any) =>
  request({
    url: '/users',
    method: 'get',
    params
  })

export const getUserInfo = () =>
  request({
    url: process.env.VUE_APP_LOGIN_API + '/user/get/getuserAndAction',
    method: 'get'
  })

export const getUserByName = (username: string) =>
  request({
    url: `/users/${username}`,
    method: 'get'
  })

export const updateUser = (username: string, data: any) =>
  request({
    url: `/users/${username}`,
    method: 'put',
    data
  })

export const deleteUser = (username: string) =>
  request({
    url: `/users/${username}`,
    method: 'delete'
  })

export const login = (data: any) =>
  request({
    url: '/users/login',
    method: 'post',
    data
  })

export const logout = () =>
  request({
    url: '/users/logout',
    method: 'post'
  })

export const register = (data: any) =>
  request({
    url: '/users/register',
    method: 'post',
    data
  })

// 获取业务系统树形菜单
export function bussinessTree(reqParams:any) {
  console.log(reqParams)
  return request({
    url: `/usermenu/get/bussinesstree/${reqParams}`,
    method: 'get'
    // params: reqParams
  })
}
