






































import { Component, Vue, Watch } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { PermissionModule } from '@/store/modules/permission'
import { SettingsModule } from '@/store/modules/settings'
import SidebarItem from './SidebarItem.vue'
import variables from '@/styles/_variables.scss'
import LangSelect from '@/components/LangSelect/index.vue'
import {
  getAccountinfo
} from '@/api/prescription'

@Component({
  name: 'SideBar',
  components: {
    SidebarItem,
    LangSelect
  }
})
export default class extends Vue {
  get sidebar() {
    return AppModule.sidebar
  }

  get lang() {
    return AppModule.language.toString()
  }

  created() {
    this.getAccountinfo()
  }

  routes = [
    {
      path: '/prescription',
      component: () => import(/* webpackChunkName: "401" */ '@/views/prescription/index.vue'),
      meta: { title: '统计时效' }
    },
    {
      path: '/getorder',
      component: () => import(/* webpackChunkName: "401" */ '@/views/getorder/getorder.vue'),
      meta: { title: '运单列表' }
    },
    {
      path: '/download',
      component: () => import(/* webpackChunkName: "401" */ '@/views/download/download.vue'),
      meta: { title: '下载中心' }
    }
  ]

    private userCode = ''
    getAccountinfo() {
      getAccountinfo().then((res:any) => {
        console.log(res)
        this.userCode = res.data.user_code
      })
    }

  @Watch('lang', { immediate: true })
    getlang(newone:any) {
      if (newone) {
        console.log(newone, 456)
        if (newone === 'en') {
          this.routes[0].meta.title = 'Statistics of time-effect'
          this.routes[1].meta.title = 'waybill list'
          this.routes[2].meta.title = 'Download Center'
        } else {
          this.routes[0].meta.title = '统计时效'
          this.routes[1].meta.title = '运单列表'
          this.routes[2].meta.title = '下载中心'
        }
      }
    }

  get menuActiveTextColor() {
    if (SettingsModule.sidebarTextTheme) {
      return SettingsModule.theme
    } else {
      return variables.menuActiveText
    }
  }

  get variables() {
    return variables
  }

  get activeMenu() {
    const route = this.$route
    const { meta, path } = route
    // if set path, the sidebar will highlight the path you set
    if (meta.activeMenu) {
      return meta.activeMenu
    }
    return path
  }

  get isCollapse() {
    return !this.sidebar.opened
  }
}
