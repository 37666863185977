import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import { RouteConfig } from 'vue-router'
import { asyncRoutes, constantRoutes, routerMap } from '@/router'
import store from '@/store'
import { bussinessTree } from '@/api/users'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'
/* Layout */
import Layout from '@/layout/index.vue'

// 根据数据装载成路由格式
function formatRoutes(aMenu: any[]) {
  // const tagRouter = []
  const aRouter = []
  let returnData = {}
  const language = AppModule.language
  console.log('lanuage', language)
  for (const i in aMenu) {
    const childrenMenu: any[] = aMenu[i].childUserRight
    let routerItem: any = {}
    const childRoute = []
    for (const j in childrenMenu) {
      let route = {}
      if (childrenMenu.length > 1) {
        routerItem = {
          path: `/${aMenu[i].umUrl.split('/')[1]}`,
          component: Layout,
          redirect: aMenu[i].umUrl,
          alwaysShow: true,
          meta: {
            title: language === 'zh' ? aMenu[i].umTitle : aMenu[i].umEnTitle,
            icon: 'tree'
          }
        }
        route = {
          path: `${childrenMenu[j].urUrl}/index`,
          component: routerMap[childrenMenu[j].urUrl.split('/')[1]],
          name: childrenMenu[j].urEnName,
          meta: {
            title: language === 'zh' ? childrenMenu[j].urName : childrenMenu[j].urEnName,
            icon: 'list'
          }
        }
      } else {
        routerItem = {
          path: `${childrenMenu[j].urUrl}`,
          component: Layout,
          redirect: `${childrenMenu[j].urUrl}/index`
        }
        route = {
          path: 'index',
          component: routerMap[childrenMenu[j].urUrl.split('/')[1]],
          name: childrenMenu[j].urEnName,
          meta: {
            title: language === 'zh' ? childrenMenu[j].urName : childrenMenu[j].urEnName,
            icon: 'list'
          }
        }
      }
      childRoute.push(route)
      routerItem.children = childRoute
    }
    aRouter.push(routerItem)
  }
  returnData = {
    aRouter: aRouter
  }
  console.log(returnData)
  return returnData
}

export interface IPermissionState {
  routes: RouteConfig[]
  dynamicRoutes: RouteConfig[]
}

@Module({ dynamic: true, store, name: 'permission' })
class Permission extends VuexModule implements IPermissionState {
  public routes: RouteConfig[] = []
  public dynamicRoutes: RouteConfig[] = []

  @Mutation
  private SET_ROUTES(routes: RouteConfig[]) {
    this.routes = constantRoutes.concat(routes)
    this.dynamicRoutes = routes
  }

  @Action({ rawError: true })
  public async GenerateRoutes(obj: any) {
    const accessedRoutes = asyncRoutes
    const powerroutes: any = await bussinessTree(obj.systemCode)
    const routerformat: any = formatRoutes(powerroutes.data)
    const lastRoutes = accessedRoutes.concat(routerformat.aRouter)
    this.SET_ROUTES(lastRoutes)
  }
}

export const PermissionModule = getModule(Permission)
