import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'

/* Layout */
import Layout from '@/layout/index.vue'

Vue.use(Router)

export const constantRoutes: RouteConfig[] = [
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/error-page/404.vue'),
    meta: { hidden: true }
  },
  {
    path: '/401',
    component: () => import(/* webpackChunkName: "401" */ '@/views/error-page/401.vue'),
    meta: { hidden: true }
  },
  {
    path: '',
    component: Layout,
    redirect: '/prescription',
    children: [
      {
        path: '/prescription',
        component: () => import(/* webpackChunkName: "401" */ '@/views/prescription/index.vue'),
        meta: { hidden: true }
      },
      {
        path: '/getorder',
        component: () => import(/* webpackChunkName: "401" */ '@/views/getorder/getorder.vue'),
        meta: { hidden: true }
      },
      {
        path: '/download',
        component: () => import(/* webpackChunkName: "401" */ '@/views/download/download.vue'),
        meta: { hidden: true }
      }
    ]
  }

]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
*/

export const asyncRoutes: RouteConfig[] = [
  // {
  //   path: '*',
  //   redirect: '/index',
  //   meta: { hidden: true }
  // }
]

const createRouter = () => new Router({
  mode: 'history', // Disabled due to Github Pages doesn't support this, enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher // reset router
}

export const routerMap: { [key: string]: any } = {
  prescription: () => import('@/views/prescription/index.vue'),
  getorder: () => import('@/views/getorder/getorder.vue'),
  download: () => import('@/views/download/download.vue')
}
export default router
